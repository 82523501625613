html,
body {
  width: 100vw;
  height: 100vh;
  .muyu-container {
    width: 100vw;
    height: 100vh;
    background-color: rgb(60, 60, 60);
    padding-top: 35vh;
    .btn{
        width: 80px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 16px;
        border: 1px solid bisque;
        background-color: bisque;
        margin: 50px auto;
        border-radius: 3px;
    }
    .auto{
      color: red;
      position: absolute;
      top: 5vh;
      left: 5vw;
      border: 1px solid red;
      border-radius: 50%;
      width: 25px;
      height: 60px;
      line-height: 30px;
    }
    .muyu-count{
        position: absolute;
        width: 100vw;
        text-align: center;
        color: bisque;
        font-weight: 700;
        top: 15vh;
        .count{
          color: red;
        }
    }
    .muyu {
      background-image: url("../static/muyu.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 100px;
      height: 100px;
      margin: 0 auto;
      transition: transform .01s;
      &.clicked{
        transform:scale(1.3)
    }
    }
    .muyu-text {
      position: absolute;
      left: 50vw;
      top: 30vh;
      animation-duration: .6s;
      animation-name: muyutext;
      animation-timing-function:ease-in-out;
      color: antiquewhite;
    }
  }
}
@keyframes muyutext {
  from {
    opacity: 1;
    top: 30vh;
    left: 50vw;
  }

  to {
    opacity: 0;
    top: 20vh;
    left: 70vw;
  }
}
