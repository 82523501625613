.audio-container{
    width: 100vw;
    height: 80px;
    position: fixed;
    bottom: 10px;
    left: 0;
    button{
       width: 60vw;
       height: 100%;
       border: 3px dashed black;
       background-color: beige;
       font-size: 20px;
    }
}